import React from 'react'
import {CFooter} from '@coreui/react'
import logo from '../assets/brand/logoNgangWhite.png';
import {motion} from "framer-motion";

const AppFooter = () => {
	return (
		<CFooter>
			{/* <div>
				<img src={logo} width={60}></img>
				<span className="ms-1">&copy; 2023</span>
			</div> */}
			<div className="ms-auto">
				<motion.div whileHover={{scale: 1.05}}>
					<span className="me-1" style={{fontSize: 15}}>Powered by</span>
					<img src={logo} width={80} alt="bankvn"></img>
				</motion.div>
			</div>
		</CFooter>
	)
}

export default React.memo(AppFooter)
