import {CButton, CModal, CModalBody, CModalFooter, CModalHeader} from "@coreui/react";
import {cilX,} from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import {motion} from "framer-motion";

export default function Modal({ data, setData }) {
	const hideModal = () => (setData({ ...data, visible: false }), true);
	return (
		<CModal
			scrollable
			visible={data.visible}
			onClose={() => setData({ ...data, visible: false })}
			alignment={data.alignment || undefined}
			size={data.size || undefined}
		>
        {data.title && <CModalHeader className="modal-bg-dark"><strong>{data.title}</strong></CModalHeader>}
        <CModalBody className="modal-bg-dark">{data.body}</CModalBody>
        <CModalFooter className="modal-bg-dark">
          <motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
            <CButton color="secondary" className="btn btn-sm text-light" variant="outline"
                     onClick={() => setData({...data, visible: false})}>
              <CIcon icon={cilX} size="sm"/>
            </CButton>
          </motion.div>
          {data.buttons.map(
            (button, i) =>
              <motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}} key={i}>
                <CButton color={button.color} className="btn btn-sm text-light"
                         onClick={() => button.handler?.(hideModal)}>
                  {button.name}
                  {button.icon && <CIcon icon={button.icon} size="sm"/>}
                </CButton>
              </motion.div>
          )}
        </CModalFooter>
		</CModal>
	);
}
