import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import {createRoot} from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {Provider} from 'react-redux'
import store from './store'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import disableDevtool from 'disable-devtool';
import ErrorBoundaryCustom from './components/ErrorBoundaryCustom'
import {HashRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";

if (process.env.REACT_APP_ENV === 'PRO')
	disableDevtool({
		md5: process.env.REACT_APP_MD5_DEBUG_DEV_TOOL,
	});

createRoot(document.getElementById('root')).render(
  <HelmetProvider>
	<Provider store={store}>
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.REACT_APP_GG_CAPTCHA_V3_SITE_KEY}
		>
			<ErrorBoundaryCustom>
				<HashRouter>
					<App />
				</HashRouter>
			</ErrorBoundaryCustom>
		</GoogleReCaptchaProvider>
  </Provider>
  </HelmetProvider>
  ,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
