import {motion} from "framer-motion";
import {CAlert} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilWarning} from "@coreui/icons";
import {Link} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";

const PleaseLogin = () => {
    const user = useSelector(state => state.user);
    return !user.isLogin && <motion.div whileHover={{scale: 1.05}}>
        <CAlert color="danger" className="d-flex align-items-center" dismissible>
            <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24}/>
            <div>Vui lòng <Link to={"/register"}>Đăng ký</Link> hoặc <Link to={"/login"}>Đăng nhập</Link> ngay để sử
                dụng tất cả dịch vụ tại BANKVN.
            </div>
        </CAlert>
    </motion.div>;
}

export default PleaseLogin;
