import {cilBurn} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {CAlert, CButton, CFormInput, CInputGroup} from "@coreui/react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import request from "src/request";
import {motion} from "framer-motion";
import SweetAlert from "./SweetAlert";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

function VerifyEmail() {
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const { register, handleSubmit, } = useForm();
	const {executeRecaptcha} = useGoogleReCaptcha();

	const showLoading = (s) => dispatch({type: 'set', loading: s});

	const onClickOpenVerifyOtp = async (evt) => {
		evt.preventDefault();
		dispatch({
			type: 'set', modal: {
				visible: true,
				title: 'Xác minh Email của bạn!',
				buttons: [],
				body: formOtp,
				alignment: 'center',
			}
		});
	}
	const onClickGetOtp = async () => {
		showLoading(true);
		let token = await executeRecaptcha();
		request.post(`/api/v1/auth/user/get-otp`, {
			email: user.email,
			sign: token,
		})
			.then(response => SweetAlert.toast.success(response.data.m))
			.catch(e => SweetAlert.toast.error(e.response?.data?.m))
			.finally(() => showLoading(false));
	}

	const onClickConfirmOtp = (form) => {
		showLoading(true);
		request.post(`/api/v1/user/user/verify`, form)
			.then(response => {
				SweetAlert.toast.success(response.data.m);
				dispatch({ type: 'set', modal: { visible: false, buttons: [] } });
			})
			.catch(e => SweetAlert.toast.error(e.response?.data?.m))
			.finally(() => showLoading(false));
	}

	const formOtp = <>
		<div>
			<p>Nhập mã xác thực để xác minh email của bạn.</p>
		</div>
		<motion.div whileHover={{scale: 1.05}}>
		<CInputGroup className="mb-3">
			<CFormInput placeholder="Nhập mã xác thực..." {...register('otp')} />
			<CButton type="button" color="warning" variant="outline" onClick={onClickGetOtp}>Lấy mã</CButton>
			<CButton type="button" color="success" variant="outline" onClick={handleSubmit(onClickConfirmOtp)}>Xác minh</CButton>
		</CInputGroup>
		</motion.div>
		<p style={{ fontSize: 14 }}>Lưu ý:<br />
			- Nhấn <code>lấy OTP</code>, một mã xác minh được gửi vào email bạn đăng ký<br />
			- Lấy mã rồi nhập mã vào <code>Nhập mã xác thực...</code><br />
			- Nhấn xác minh để hoàn tất!<br/>
			<span className="text-warning">- Nếu không thấy mail OTP, vui lòng đọc trong tin nhắn SPAM!</span>
		</p>
	</>;
	return user.verified === false && <CAlert color="warning" className="d-flex align-items-center" dismissible>
		<CIcon icon={cilBurn} className="flex-shrink-0 me-2" width={24} height={24} />
		<div>Bạn chưa xác minh email, để an toàn và trách mất tài khoản vui lòng <a href="#" onClick={onClickOpenVerifyOtp}>nhấn vào đây</a> để xác minh.</div>
	</CAlert>;
}

export default VerifyEmail;
