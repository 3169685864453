import {ErrorBoundary} from 'react-error-boundary';
import request from 'src/request';
import Page500ClientError from 'src/views/404/500ClientError';
import {useSelector} from "react-redux";
import Util from "../utils/util";

function ErrorBoundaryCustom({ children }) {
    const user = useSelector(state => state.user);
    function FallbackComponent({ error, resetErrorBoundary }) {
        return <Page500ClientError
            error={error}
            resetErrorBoundary={resetErrorBoundary}
            title={Util.introTitle("Lỗi hệ thống")}
        />
    }
    function onReset() {

    }
    function onError(e) {
        if (process.env.REACT_APP_ENV !== 'LOCAL') {
            let data = {message: e.message, stack: e.stack, user: user?.username || ''};
            request.post("/api/v1/report/user/report-error", data)
                .then(_ => { })
                .catch(_ => { });
        }
    }
    return <>
        <ErrorBoundary
            FallbackComponent={FallbackComponent}
            onReset={onReset}
            onError={onError}
        >
            {children}
        </ErrorBoundary>
    </>;

}

export default ErrorBoundaryCustom;
