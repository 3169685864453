import axios from "axios";
import Util from "./utils/util";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const request = axios.create({
  baseURL: REACT_APP_BASE_URL,
  timeout: 30_000,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + (localStorage.getItem("access-token") || ""),
  },
  responseType: "json",
  responseEncoding: "utf8",
});

request.interceptors.response.use(response => response, error => {
  if (error.response.status === 401)
    localStorage.clear();
  return Promise.reject(error);
});

request.reloadUser = ({
  success = Util.defaultFunction,
  error = Util.defaultFunction,
  final = Util.defaultFunction,
}) => {
  if (localStorage.getItem("access-token"))
    request.get("/api/v1/user/user/info")
      .then(response => success(response))
      .catch(err => error(err))
      .finally(() => final());
  else
    final();
}

export default request;
