import React from 'react'
import {
	CAvatar,
	CBadge,
	CDropdown,
	CDropdownDivider,
	CDropdownHeader,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from '@coreui/react'
import {
	cilAccountLogout,
	cilBell,
	cilCommentSquare,
	cilCreditCard,
	cilEnvelopeOpen,
	cilFile,
	cilSettings,
	cilTask,
	cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar from '../../assets/images/avatars/9.jpg'

import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {firstUpperCase} from 'src/utils/string.util'
import request from 'src/request'
import SweetAlert from "../SweetAlert";
import {motion} from "framer-motion";

const AppHeaderDropdown = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector(state => state.user);
	const showLoading = (s) => dispatch({type: 'set', loading: s});

	const handlerLogout = () => {
		showLoading(true);
		request.delete("/api/v1/user/user/logout")
			.catch(_ => 0)
			.finally(() => {
				showLoading(false);
				localStorage.clear();
				SweetAlert.toast.success('Đăng xuất thành công, xin chờ giây lát...');
				setTimeout(() => window.location.reload(), 2000);
			});
	}

	return (
		<CDropdown variant="nav-item" style={{ cursor: 'pointer' }}>
			<CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
				<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
				<CAvatar src={avatar} size="md" />
				</motion.div>
			</CDropdownToggle>
			<CDropdownMenu className="pt-0" placement="bottom-end">
				<CDropdownHeader className=" fw-semibold py-2" style={{backgroundColor: '#494a52'}}>Hi {firstUpperCase(user?.username || "Bạn")}!</CDropdownHeader>
				<CDropdownItem>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilBell} className="me-2" />
					Cập nhật
					<CBadge color="info" className="ms-2">
						42
					</CBadge>
					</motion.div>
				</CDropdownItem>
				<CDropdownItem>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilEnvelopeOpen} className="me-2" />
					Tin nhắn
					<CBadge color="success" className="ms-2">
						42
					</CBadge>
					</motion.div>
				</CDropdownItem>
				<CDropdownItem>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilTask} className="me-2" />
					Việc
					<CBadge color="danger" className="ms-2">
						42
					</CBadge>
					</motion.div>
				</CDropdownItem>
				<CDropdownItem>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilCommentSquare} className="me-2" />
					Bình luận
					<CBadge color="warning" className="ms-2">
						42
					</CBadge>
					</motion.div>
				</CDropdownItem>
				<CDropdownHeader className="fw-semibold py-2"  style={{backgroundColor: '#494a52'}}>Cài đặt</CDropdownHeader>
				<CDropdownItem onClick={() => navigate('/user/profile')}>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilUser} className="me-2" />
					Trang cá nhân
					</motion.div>
				</CDropdownItem>
				<CDropdownItem>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilSettings} className="me-2" />
					Cài đặt
					</motion.div>
				</CDropdownItem>
				<CDropdownItem>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilCreditCard} className="me-2" />
					Thanh toán
					<CBadge color="secondary" className="ms-2">
						42
					</CBadge>
					</motion.div>
				</CDropdownItem>
				<CDropdownItem>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilFile} className="me-2" />
					Dự án
					<CBadge color="primary" className="ms-2">
						42
					</CBadge>
					</motion.div>
				</CDropdownItem>
				<CDropdownDivider />
				<CDropdownItem onClick={handlerLogout}>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilAccountLogout} className="me-2" />
					Đăng xuất
					</motion.div>
				</CDropdownItem>
			</CDropdownMenu>
		</CDropdown>
	)
}

export default React.memo(AppHeaderDropdown)
