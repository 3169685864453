import {createStore} from 'redux'

const initialState = {
	sidebarShow: true,
	user: {isLogin: false, demo: false},
	loading: false,
	toast: null,
	modal: { visible: false, title: "", buttons: [], body: <></> },
	config: {
    minimumAmountDeposit: 10000,
    maximumAmountDeposit: 30_000_000,
		isMaintain: false,
		timeReloadCheckUser: 1,
		youtubeUseManualBank: "",
		youtubeUseManualPaymentDeposit: "",
		youtubeUseManualEarnMoney: "",
		youtubeUseManualDownloadApp: "",
		youtubeUseManualTool: "",
		linkTelegramGroup: "",
		button: {
			login: {
				status: true,
			},
			loginGoogle: {
				url: "",
				status: false,
			},
			register: {
				status: true,
			},
			forgotPassword: {
				status: true,
			},
		}

	},
}

const changeState = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case 'set':
			return { ...state, ...rest }
		default:
			return state
	}
}

const store = createStore(changeState)
export default store
