import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySweetAlert = withReactContent(Swal);
const AlertToast = MySweetAlert.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    background: '#3b3c45',
    color: 'white',
    didOpen: (toast) => {
        toast.onmouseenter = MySweetAlert.stopTimer;
        toast.onmouseleave = MySweetAlert.resumeTimer;
    }
});

const SweetAlert = {
    DEFAULT_ERROR_MESSAGE: "Có lỗi xẩy ra vui lòng thử lại!",
    DEFAULT_SUCCESS_MESSAGE: "Thực hiện thành công",
    instance: MySweetAlert,
    toast: {
        instance: AlertToast,
        success: (text, args) => {
            AlertToast.fire({
                icon: "success",
                text: text || SweetAlert.DEFAULT_SUCCESS_MESSAGE,
                ...args
            }).then();
        },
        warning: (text, args) => {
            AlertToast.fire({icon: "warning", text, ...args}).then();
        },
        error: (text, args) => {
            AlertToast.fire({
                icon: "error",
                text: text || SweetAlert.DEFAULT_ERROR_MESSAGE,
                ...args
            }).then();
        },
        info: (text, args) => {
            AlertToast.fire({icon: "info", text, ...args}).then();
        },
        question: (text, args) => {
            AlertToast.fire({icon: "question", text, ...args}).then();
        },
    }
}
export default SweetAlert;
