import React from 'react'

// Pages
const Login = React.lazy(() => import('./views/auth/Login'))
const Register = React.lazy(() => import('./views/auth/Register'))
const Landing = React.lazy(() => import('./views/landing/Landing'))
const Maintain = React.lazy(() => import('./views/404/Maintain'))
const LockPage = React.lazy(() => import('./views/404/LockPage'))

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const routesIndex = [
  {path: '/', name: '', element: Landing},
  {path: '/login', name: 'Đăng nhập', element: Login},
  {path: '/register', name: 'Đăng ký', element: Register},
  {path: '/maintain', name: 'Bảo trì', element: Maintain},
  {path: '/lock-page', name: 'Phiên hết hạn', element: LockPage},
  {path: '*', name: '', element: DefaultLayout},
];

export default routesIndex;
