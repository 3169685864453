import { CToast, CToastBody, CToaster, CToastHeader } from "@coreui/react";
import { useEffect, useRef, useState } from "react";

export default function Toasts({ data }) {
	const [toast, addToast] = useState(0);
	const model = (<CToast style={{backgroundColor: '#3b3c45'}}>
		<CToastHeader closeButton style={{backgroundColor: '#3b3c45'}}>
			<svg
				className="rounded me-2"
				width="20"
				height="20"
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="xMidYMid slice"
				focusable="false"
				role="img"
			>
				<rect width="100%" height="100%" fill={data?.color || "red"}></rect>
			</svg>
			<div className="fw-bold me-auto">{data?.title || "Thông báo"}</div>
			<small>{data?.time || "Vừa xong"}</small>
		</CToastHeader>
		<CToastBody>{data?.body || "..."}</CToastBody>
	</CToast>);
	useEffect(() => {
		if (data !== null)
			addToast(model);
	}, [data]);
	const toaster = useRef();
	return <CToaster ref={toaster} push={toast} placement="top-end" />;
}
