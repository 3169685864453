import React from 'react'
import CIcon from '@coreui/icons-react'
import {
	cilBank,
	cilBarChart,
	cilCloudDownload,
	cilContact,
	cilFeaturedPlaylist,
	cilFire,
	cilHistory,
	cilLockLocked,
	cilMoney,
	cilTransfer,
	cilUser,
	cilWallet
} from '@coreui/icons'
import {CNavGroup, CNavItem, CNavTitle} from '@coreui/react'

const _nav = [
	{
		component: CNavItem,
	},
	{
		component: CNavTitle,
		name: 'Fake App',
	},
	{
		component: CNavItem,
		name: 'Fake App Ngân Hàng',
		to: '/bank',
		icon: <CIcon icon={cilBank} customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'Tải App Ngân Hàng',
		to: '/bank/download',
		icon: <CIcon icon={cilCloudDownload} customClassName="nav-icon" />,
	},
    {
        component: CNavGroup,
        name: 'Gói Ngân Hàng',
        icon: <CIcon icon={cilFire} customClassName="nav-icon"/>,
        items: [
            {
                component: CNavItem,
                name: 'Gói Vietcombank',
                to: '/package/bank/vietcombank',
            },
            {
                component: CNavItem,
                name: 'Gói Techcombank',
                to: '/package/bank/techcombank',
            },
            {
                component: CNavItem,
                name: 'Gói Đặc Biệt',
                to: '/package/bank/all',
            },
        ],
    },
	{
		component: CNavTitle,
		name: 'Fake Bill',
	},
	{
		component: CNavItem,
		name: 'Bill Chuyển Khoản',
		to: '/bill/bill-transfer',
      icon: <CIcon icon={cilTransfer} customClassName="nav-icon"/>,
	},
	{
		component: CNavItem,
		name: 'Bill Biến Động',
		to: '/bill/bill-flutuation',
      icon: <CIcon icon={cilBarChart} customClassName="nav-icon"/>,
	},
	{
		component: CNavItem,
		name: 'Bill Số Dư',
		to: '/bill/bill-surplus',
      icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon"/>,
	},
    {
        component: CNavGroup,
        name: 'Gói Fake Bill',
        icon: <CIcon icon={cilFire} customClassName="nav-icon"/>,
        items: [
            {
                component: CNavItem,
                name: 'Gói Chuyển Khoản',
                to: '/package/bill/transfer',
            },
            {
                component: CNavItem,
                name: 'Gói Biến Động',
                to: '/package/bill/balance_fluct',
            },
            {
                component: CNavItem,
                name: 'Gói Số dư',
                to: '/package/bill/balance',
            },
            {
                component: CNavItem,
                name: 'Gói Đặc Biệt',
                to: '/package/bill/all',
            },

        ],
    },
    {
		component: CNavTitle,
		name: 'Chức Năng',
	},
	{
		component: CNavItem,
		name: 'Nạp Tiền',
		to: '/payment/deposit',
		icon: <CIcon icon={cilWallet} customClassName="nav-icon" />,
	},
	{
		component: CNavItem,
		name: 'Kiếm Tiền',
		to: '/payment/earn-money',
		icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
		badge: {
			color: 'danger',
			text: 'HOT',
		},
	},
	{
		component: CNavTitle,
		name: 'Công cụ',
	},
	{
		component: CNavItem,
		name: 'Kiểm Tra Hồ Sơ',
		to: '/tool/check-profile',
		icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
		badge: {
			color: 'danger',
			text: 'HOT',
		},
	},
	{
		component: CNavTitle,
		name: 'Tài khoản',
		checkLogin: true,
	},
	{
		component: CNavItem,
		name: 'Trang cá nhân',
		to: '/user/profile',
		icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
		checkLogin: true,
	},
	{
		component: CNavItem,
		name: 'Đổi mật khẩu',
		to: '/user/change-password',
		icon: <CIcon icon={cilLockLocked} customClassName="nav-icon" />,
		checkLogin: true,
	},
	{
		component: CNavItem,
		name: 'Lịch Sử Giao Dịch',
		to: '/transaction/history',
		icon: <CIcon icon={cilHistory} customClassName="nav-icon" />,
		checkLogin: true,
	},
]

export default _nav
