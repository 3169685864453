import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import routes from '../routes'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import { motion } from "framer-motion";

const AppBreadcrumb = () => {
	const currentLocation = useLocation().pathname

	const getRouteName = (pathname, routes) => {
		const currentRoute = routes.find((route) => route.path === pathname)
		return currentRoute ? currentRoute.name : false
	}

	const getBreadcrumbs = (location) => {
		const breadcrumbs = []
		location.split('/').reduce((prev, curr, index, array) => {
			const currentPathname = `${prev}/${curr}`
			const routeName = getRouteName(currentPathname, routes)
			routeName &&
				breadcrumbs.push({
					pathname: currentPathname,
					name: routeName,
					active: index + 1 === array.length,
				})
			return currentPathname
		})
		return breadcrumbs
	}

	const breadcrumbs = getBreadcrumbs(currentLocation)

	return (
		<CBreadcrumb className="m-0 ms-2">
			<motion.div whileHover={{ scale: 1.05 }}>
				<CBreadcrumbItem><Link to={"/dashboard"}>Trang chủ</Link></CBreadcrumbItem>
			</motion.div>
			{breadcrumbs.map((breadcrumb, index) => {
				return (
					<motion.div whileHover={{ scale: 1.05 }} key={index}>
						<CBreadcrumbItem
							{...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
						>&nbsp;/&nbsp;
							{breadcrumb.name}
						</CBreadcrumbItem>
					</motion.div>
				)
			})}
		</CBreadcrumb>
	)
}

export default React.memo(AppBreadcrumb)
