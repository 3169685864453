import React from "react";
import {HashLoader, SyncLoader} from "react-spinners";

const Loading = ({ visible }) => {
	return visible && <div style={{
		width: '100%',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'fixed',
		backgroundColor: 'rgb(40, 48, 70, 0.7)',
		zIndex: 9999999999999,
	}}>
		<HashLoader
			color={"#fff"}
			loading={visible}
			size={100}
			aria-label="Loading Spinner"
			data-testid="loader"

		/>
	</div>
}

export default Loading;
