import React from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
	CButton,
	CContainer,
	CHeader,
	CHeaderDivider,
	CHeaderNav,
	CHeaderToggler,
	CNavItem,
	CNavLink,
	CPopover,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilMenu, cilMoney} from '@coreui/icons'
import {AppHeaderDropdown} from './header/index'
import {formatMoney} from 'src/utils/number.util'
import {motion} from "framer-motion";
import {AppBreadcrumb} from "./index";
import request from "../request";
import SweetAlert from "./SweetAlert";

const AppHeader = () => {
	const dispatch = useDispatch()
	const user = useSelector(state => state.user);
	const sidebarShow = useSelector((state) => state.sidebarShow)
	const navigate = useNavigate();
    const showLoading = (s) => dispatch({type: 'set', loading: s});

    const onClickOpenBank = async () => {
        dispatch({
            type: 'set', modal: {
                visible: true,
                title: (user.demo ? 'Tắt' : 'Bật') + " chế độ dùng thử",
                buttons: [{
                    color: 'success',
                    name: (user.demo ? 'Tắt' : 'Bật') + ' dùng thử & Đăng xuất',
                    handler: (hideModal) => hideModal() && onHandlerTurnOnDemo()
                }],
                body: <>
                    <div>
                        {!user.demo && <div>
                            <span>Việc bật chế độ dùng thử, bạn được sử dụng không giới hạn tài nguyên trên BANKVN</span><br/>
                            <span>Lưu ý:</span><br/>
                            <span>- Tất cả TK đã tạo và sắp tạo sẽ ra tên BANKVN</span><br/>
                            <span>- Chuyển tiền trên APP auto ra tên người chuyển là TK hệ thống</span><br/>
                            <span>- Tạo bill sẽ không sử dụng được</span><br/>
                            <span>- Kiểm tra hồ sơ auto ra thông tin từ nguời dùng TK hệ thống</span><br/>
                            <span>- Chỉ có thể tạo TK ngân hàng khi chưa có TK nào</span><br/>
                            <span>- Tắt chế độ dùng thử, mọi thứ sẽ trở lại như cũ</span><br/><br/>
                        </div>}
                        <span>Bạn chắc chắn muốn {(user.demo ? 'tắt' : 'mở')} chế độ dùng thử chứ?</span>
                    </div>
                </>,
            }
        });
    }

    const onHandlerTurnOnDemo = () => {
        showLoading(true);
        request.post(`/api/v1/user/user/turn-on-demo`)
            .then(response => {
                SweetAlert.toast.success(response.data?.m);
                setTimeout(() => window.location.reload(), 2000);
            })
            .catch(e => SweetAlert.toast.error(e?.response?.data?.m))
            .finally(() => showLoading(false));
    }
	function HeaderUserLogin() {
		return <CHeaderNav style={{cursor: 'pointer'}}>
			<CNavItem>
				<CNavLink className='position-relative'>
					<CPopover content='Tài khoản chính' trigger='hover' placement="bottom">
						<motion.div whileHover={{scale: 1.05}}>
                <CIcon icon={cilMoney} size="sm" className="text-primary mr-1" style={{marginTop: 10}}/>
							{formatMoney(user.demo ? 999_999_999 : (user.money?.main || 0))}đ
						</motion.div>
					</CPopover>
				</CNavLink>
			</CNavItem>
			<CNavItem className='d-none d-sm-block'>
				<CNavLink className='position-relative'>
					<CPopover content='Tài khoản phụ' trigger='hover' placement="bottom">
						<motion.div whileHover={{scale: 1.05}}>
                <CIcon icon={cilMoney} size="sm" className="text-danger" style={{marginTop: 10}}/>
							{formatMoney(user.demo ? 999_999_999 : (user.money?.test || 0))}đ
						</motion.div>
					</CPopover>
				</CNavLink>
			</CNavItem>
			<CNavItem className='d-none d-sm-block'>
				<CNavLink className='position-relative'>
					<CPopover content='Tài khoản giới thiệu' trigger='hover' placement="bottom">
						<motion.div whileHover={{scale: 1.05}}>
                <CIcon icon={cilMoney} size="sm" className="text-warning" style={{marginTop: 10}}/>
							{formatMoney(user.demo ? 999_999_999 : (user.money?.ref || 0))}đ
						</motion.div>
					</CPopover>
				</CNavLink>
			</CNavItem>
        <CNavItem>
            <CNavLink className='position-relative'>
                <CPopover content={user.demo ? 'Tắt chế độ dùng thử' : 'Bật chế độ dùng thử'} trigger='hover'
                          placement="bottom">
                    <motion.div whileHover={{scale: 1.05}}>
                        <CButton type="submit"
                                 color={user.demo ? 'danger' : 'success'}
                                 style={{height: 30, fontSize: 13}}
                                 className='text-white'
                                 onClick={onClickOpenBank}
                        >{user.demo ? 'Tắt' : 'Bật'} dùng thử</CButton>
                    </motion.div>
                </CPopover>
            </CNavLink>
        </CNavItem>
		</CHeaderNav>;
	}

	function HeaderUserNotLogin() {
		return <CHeaderNav style={{cursor: 'pointer', marginLeft: 'auto'}}>
			<CNavItem>
				<CNavLink className='position-relative'>
					<CPopover content='Đăng nhập vào hệ thống' trigger='hover' placement="bottom">
						<motion.div whileHover={{scale: 1.05}}>
							<CButton type="submit" color='primary'
									 size='sm'
									 variant={"outline"}
									 onClick={_=> navigate("/login")}
							>Đăng nhập</CButton>
						</motion.div>
					</CPopover>
				</CNavLink>
			</CNavItem>
			<CNavItem>
				<CNavLink className='position-relative'>
					<CPopover content='Đăng ký tài khoản mới' trigger='hover' placement="bottom">
						<motion.div whileHover={{scale: 1.05}}>
							<CButton type="submit" color='success'
									 size='sm'
									 className='text-white'
									 onClick={_=> navigate("/register")}
							>Đăng Ký</CButton>
						</motion.div>
					</CPopover>
				</CNavLink>
			</CNavItem>

		</CHeaderNav>;
	}
	return (
		<CHeader position="sticky" className="mb-4" >
			<CContainer fluid>
				<CHeaderToggler
					className="ps-1"
					onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
				>
					<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
					<CIcon icon={cilMenu} size="lg" />
					</motion.div>
				</CHeaderToggler>
				{/* <CHeaderBrand className="mx-auto d-md-none" to="/">
					🏦 BankVn.App
					<CIcon icon={logo} height={48} alt="Logo" />
				</CHeaderBrand> */}
				<CHeaderNav className="d-none d-md-flex me-auto">
					<CNavItem>
						<motion.div whileTap={{scale: 0.95}} whileHover={{scale: 1.05}}>
						<CNavLink to="/dashboard" component={NavLink}>
							Trang chủ
						</CNavLink>
						</motion.div>
					</CNavItem>
				</CHeaderNav>
				{user.isLogin ? <HeaderUserLogin></HeaderUserLogin> : <HeaderUserNotLogin></HeaderUserNotLogin>}
				<CHeaderNav>
					{user.isLogin ? <AppHeaderDropdown/> : <></>}
				</CHeaderNav>
			</CContainer>
			<CHeaderDivider />
			<CContainer fluid>
				<AppBreadcrumb />
			</CContainer>
		</CHeader>
	)
}

export default AppHeader
