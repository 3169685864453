import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react'
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

export const AppSidebarNav = ({ items }) => {
	const location = useLocation();
	const user = useSelector(state => state.user);

	const filterLogin = (item) => {
		if (item.checkLogin)
			if (!user.isLogin)
				return false;
		return true;
	}

	const navLink = (name, icon, badge) => {
		return (
			<>
				{icon && icon}
				{name && name}
				{badge && (
					<CBadge color={badge.color} className="ms-auto">
						{badge.text}
					</CBadge>
				)}
			</>
		)
	}

	const navItem = (item, index) => {
		const { component, name, badge, icon, ...rest } = item
		const Component = component
		delete rest.checkLogin;
		return (
			<motion.div whileTap={{ scale: 0.95 }} whileHover={{ scale: 1.05 }} key={index}>
				<Component
					{...(rest.to &&
						!rest.items && {
						component: NavLink,
					})}
					{...rest}
				>
					{navLink(name, icon, badge)}
				</Component>
			</motion.div>
		)
	}
	const navGroup = (item, index) => {
		const { component, name, icon, to, ...rest } = item
		const Component = component
		delete rest.checkLogin;
		return (
			<motion.div whileTap={{ scale: 0.95 }} whileHover={{ scale: 1.05 }} key={index}>
				<Component
					idx={String(index)}
					toggler={navLink(name, icon)}
					visible={location.pathname.startsWith(to)}
					{...rest}
				>
					{item.items?.map((item, index) =>
						item.items ? navGroup(item, index) : navItem(item, index),
					)}
				</Component>
			</motion.div>
		)
	}

	return (
		<React.Fragment>
			{items &&
				items
					.filter(filterLogin)
					.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
		</React.Fragment>
	)
}

AppSidebarNav.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
