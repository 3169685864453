import React from 'react'
import {CButton, CCol, CContainer, CFormInput, CInputGroup, CInputGroupText, CRow,} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {cilBug} from '@coreui/icons'
import {useSelector} from "react-redux";
import logo from "../../assets/brand/logoNgang.png"
import {Helmet} from "react-helmet-async";
import Util from "../../utils/util";
import {useLocation} from "react-router-dom";

const Page500ClientError = ({ error, resetErrorBoundary, title }) => {
  const location = useLocation();
  const config = useSelector(state => state.config);
  return (
    <CContainer fluid style={{
      width: '100%',
      height: '100vh',
      alignItems: 'center',
      // backgroundColor: 'rgb(40, 48, 70, 0.7)',
    }}>
      <Helmet>
        <title>{Util.introTitle(title)}</title>
        <link rel="canonical" href={Util.canonical(location.pathname)}/>
      </Helmet>
      <CRow className="justify-content-center">
        <CCol md={6}>
          <div className="clearfix">
            <h1 className="float-start display-3 me-4"></h1>
            <h4 className="pt-3">
              <img src={logo} className="img-fluid" alt="logo"/>
              {/* Có vẻ như trang đó không tồn tại. <br></br>Vui lòng kiểm tra URL và thử lại ! */}
            </h4>
            <p className="float-start" style={{color: 'red'}}>
              Trang bạn truy cập đã xảy ra lỗi :(, chúng tôi đã ghi nhận lỗi và sớm khắc phục.
            </p>
          </div>
          <CInputGroup className="input-prepend">
            <CInputGroupText>
              <CIcon icon={cilBug}/>
            </CInputGroupText>
            <CFormInput type="text" placeholder="Chụp hình & Báo lỗi cho CSKH để nhận tiền thưởng💵!" value={""}/>
            <CButton color="danger" className="text-light"
                     onClick={_ => location.href = config?.linkTelegramGroup || '/'}>Báo lỗi CSKH</CButton>
          </CInputGroup>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default Page500ClientError
