import React, {Suspense, useEffect, useState} from 'react'
import {Route, Routes, useNavigate} from 'react-router-dom'
import './scss/style.scss'
import 'sweetalert2/src/sweetalert2.scss'
import {Loading, Modal, Toast} from './components'
import {useDispatch, useSelector} from 'react-redux'
import request from "./request";
import SweetAlert from './components/SweetAlert';
import LoadingLazyComponent from "./components/LoadingLazyComponent";
import routesInit from "./routesInit";


const App = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const load = useSelector(state => state.loading);
	const toast = useSelector(state => state.toast);
	const modal = useSelector(state => state.modal);
	const [isAcceptLoadUser, setIsAcceptLoadUser] = useState(false);
	const config = useSelector(state => state.config);


	const requestReloadUser = () => {
		const reload = () => setTimeout(() => requestReloadUser(), config.timeReloadCheckUser);
		request.reloadUser({
			error: err => {
				if (err.response?.status === 401) {
					dispatch({type: 'set', user: {isLogin: false,}});
					return;
				}
				SweetAlert.toast.error(err?.response?.data?.m);
				reload();
			},
			success: ({data}) => {
				dispatch({type: 'set', user: {...data.d, isLogin: true}});
				reload();
			},
		});
	}

	const loadConfig = () => {
		request.get(`/api/v1/config/user-web/data`)
			.then(response => {
				let config = {...response.data.d, isLoaded: true};
				dispatch({type: 'set', config});
				if (config.isMaintain)
					throw config.isMaintain;
			})
			.catch(_ => navigate('/maintain'));
	}

	useEffect(() => {
		loadConfig();
		navigate(location.pathname);
		setIsAcceptLoadUser(true);
	}, []);

	useEffect(() => {
		if (isAcceptLoadUser)
			requestReloadUser();
	}, [config]);

	return (
		<div>
			<Loading visible={load} />
			<Suspense fallback={<LoadingLazyComponent/>}>
				<Routes>
            {routesInit.map((route, idx) => {
                return (
                    route.element && (
                        <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            element={<route.element title={route.name}/>}
                        />
                    )
                )
            })}
				</Routes>
			</Suspense>
			<Toast data={toast} />
			<Modal data={modal} setData={d => dispatch({ type: 'set', modal: d })} />
		</div>
	);
}

export default App
